/* 
interface Gift {
  id: number;
  gift: string;
  image: string;
  price: number;
  quantity: number;
}
*/

async function FetchGifts() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/list`);
  const data = await response.json();
  return data;
}

export default FetchGifts;

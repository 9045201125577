// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import FetchGifts from "services/gifts";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';

// Material Kit 2 React components
import MKBox from "muicomponents/MKBox";

// Material Kit 2 React components
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";

import BuyModal from "pages/Rental/components/Modal";

function Gifts({ limit }) {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedGiftID, setSelectedGiftID] = useState(-1);

  const toggleModal = (giftID) => {
    setSelectedGiftID(giftID)
    setShow(!show)
  }

  const actionProps = {
    color: "secondary",
    label: "Presentear",
  };

  const [gifts, setGifts] = useState([]);
  useEffect(() => {
    FetchGifts().then((data) => {
      const gs = data.filter((gift) => gift.quantity > 0)
      if (limit > 0) {
        setGifts(gs.slice(0, limit))
      } else {
        setGifts(gs)
      }
      setLoading(false)
    }
    );
  }, [limit]);

  const renderGifts = () => (
    <Grid container spacing={3} sx={{ mt: 3 }}>
      {gifts.map((gift) => {
        return (
          <Grid item xs={12} md={6} lg={4} key={gift.id}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={gift.image}
                title={gift.gift}
                description={`R$ ${gift.price.toFixed(2)}`}
                categories={["Cotas", gift.quantity]}
                action={{ onClick: () => toggleModal(gift.id), ...actionProps }}
              />
            </MKBox>
          </Grid>
        )
      })}
    </Grid>
  )

  const renderLoading = () => (
    <Grid
      container
      justifyContent="center"
      textAlign="center"
      flexDirection="row"
      md={9}
      mx="auto"
      pb={30}
      pt={20}
    >
      <CircularProgress color="inherit" />
    </Grid>

  )

  return (
    <MKBox component="section" py={3}>
      <Container>
        <BuyModal show={show} toggleModal={toggleModal} giftID={selectedGiftID} />
        {loading ? renderLoading() : renderGifts()}
      </Container>
    </MKBox>
  );
}

// Typechecking props for the Gifts
Gifts.propTypes = {
  limit: PropTypes.number,
};

export default Gifts;

import theme from "assets/theme";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import useMediaQuery from '@mui/material/useMediaQuery';

// Material Kit 2 React components
import MKBox from "muicomponents/MKBox";
import MKTypography from "muicomponents/MKTypography";

import logo from "assets/images/wedding/logo.png";

function AboutUs() {
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <MKBox component="section" pt={12} pb={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={9}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "justify" }}
          flexDirection="column"
          alignItems="center"
        >
          <MKBox component="img" src={logo} width={largeScreen ? "30%" : "50%"} borderRadius="xl" mb={2} />
          <MKTypography variant="h2">Seja bem-vindo!</MKTypography>
          {/* <MKTypography variant="h2" color="info" textGradient mb={2}>
            1,679,477+ web developers
          </MKTypography> */}
          <MKTypography
            variant="body1"
            sx={({ breakpoints, typography: { body2 } }) => ({
              [breakpoints.down("md")]: { fontSize: body2.fontSize }
            })}
            color="text"
            mb={2}
            mt={2}
          >
            É com imensa alegria que compartilhamos este espaço especial com você. Aqui, você 
            encontrará todas as informações sobre o nosso grande dia. Explore os detalhes da 
            cerimônia, conheça nossa história de amor e confirme sua presença. Celebrar este 
            momento único com pessoas queridas como você é nosso maior desejo. Agradecemos por 
            fazer parte desta jornada e mal podemos esperar para compartilhar memórias preciosas 
            e emoções intensas neste dia mágico. Sinta-se em casa e aproveite cada página deste 
            site, criado com muito carinho para você.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutUs;

async function FetchGuests(code) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      "code": `${code}`
    })
  };
  const response = await fetch(`${process.env.REACT_APP_API_URL}/guestsByCode`, requestOptions);
  const data = await response.json();
  return data;
}

async function ConfirmGuests(guestsToConfirm) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      "guests": guestsToConfirm
    })
  };
  return fetch(`${process.env.REACT_APP_API_URL}/confirmGuests`, requestOptions)
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      return res.json().then(text => { throw new Error(text) });
    })
}

export { FetchGuests, ConfirmGuests };
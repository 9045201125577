import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';

// Material Kit 2 React components
import MKBox from "../../../muicomponents/MKBox";
import MKInput from "../../../muicomponents/MKInput";
import MKButton from "../../../muicomponents/MKButton";
import MKTypography from "../../../muicomponents/MKTypography";

// Images
import image from "assets/images/wedding/RSVP.png";

import { FetchGuests, ConfirmGuests } from "services/rsvp"

function RsvpCta() {
  const [code, setCode] = useState("");
  const [guests, setGuests] = useState([]);
  const [alert, setAlert] = useState(null);
  const [alertMessage, setAlertMessage] = useState({ "severity": "success", "message": "" });
  const [checked, setChecked] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCode = () => {
    setIsLoading(true)
    FetchGuests(code).then((res) => {
      if (res.length === 0) {
        setAlert(true)
        setAlertMessage({ "severity": "error", "message": "Não foram encontrados convidados com esse código" })
      } else {
        var confMap = {};
        res.forEach(g => {
          confMap[g.name] = true
        })
        setChecked(confMap)
        setGuests(res)
        setAlert(null)
        setAlertMessage({ "severity": "", "message": "" })
      }
      setIsLoading(false)
    });
  }

  const handleCheck = (event) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    });
  };

  const handleConfirm = () => {
    setIsLoading(true)
    const toConfirm = guests.filter((g) => checked[g.name])
    ConfirmGuests(toConfirm).then((res) => {
      setAlert(res)
      setAlertMessage({ "severity": "success", "message": res.replace("{", "").replace("}", "") })
      setIsLoading(false)
      setTimeout(() => navigate("/gifts"), 3000);
    })
      .catch(error => {
        setAlert(error)
        setAlertMessage({ "severity": "error", "message": error.message.replace("{", "").replace("}", "") })
        setIsLoading(false)
      })
  };

  const renderCodeInput = () => (
    <>
      <Grid item xs={12} sm={5}>
        <MKInput
          value={code}
          onChange={(event) => {
            setCode(event.target.value.toUpperCase());
          }}
          label="Insira seu código"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        {isLoading ? <CircularProgress color="inherit" /> : (
          <MKButton variant="contained" color="info" sx={{ height: "100%" }} onClick={handleCode} >
            Enviar
          </MKButton>
        )}
      </Grid>
      {alert != null ?
        <Grid item>
          <Alert severity={alertMessage.severity} width="100%">{alertMessage.message}</Alert>
        </Grid>
        : <></>
      }
    </>
  )

  const renderConfirmation = () => (
    <>
      <Divider width="100%" />
      <FormControl sx={{ ml: 2, width: "100%" }} component="fieldset" variant="standard">
        <FormLabel component="legend" filled color="text">Confirmar convidados abaixo:</FormLabel>
        <FormGroup>
          {
            guests.map((guest, index) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox name={guest.name} checked={checked[guest.name]} onChange={handleCheck} />
                  }
                  label={guest.name}
                  key={index}
                  sx={{ m: -0.5 }}
                />
              )
            })
          }
        </FormGroup>
        {alert != null ? <Alert severity={alertMessage.severity} width="100%">{alertMessage.message}</Alert> : isLoading ? <CircularProgress color="inherit" /> : (<Grid item xs={12} sm={4} sx={{ mt: 2 }}>
          <MKButton variant="contained" color="info" sx={{ height: "100%" }} onClick={handleConfirm} >
            Confirmar
          </MKButton>
        </Grid>)}
      </FormControl>
    </>
  )

  return (
    <MKBox component="section" id="rsvp" py={12} mt={{ xs: 6, lg: 24 }}>
      <MKBox bgColor="white" py={12} borderRadius={10} px={{ xs: 3, lg: 0 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={6} ml="auto">
            <MKTypography variant="h4" mb={1}>
              Confirmação de presença
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Junte-se a nós nesse capítulo especial da nossa história
            </MKTypography>
            <Grid container spacing={2}>
              {guests.length == 0 ? renderCodeInput() : renderConfirmation()}
            </Grid>
          </Grid>
          <Grid item xs={12} xl={4} position="relative">
            <MKBox
              component="img"
              src={image}
              alt="image"
              maxWidth="18.75rem"
              width="100%"
              borderRadius="lg"
              shadow="xl"
              mt={{ lg: 0, xl: -24 }}
              display={"block"}
            />
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
  );
}

export default RsvpCta;

import { useState, useEffect } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { cpf } from 'cpf-cnpj-validator';

import thankYou from "assets/images/wedding/thankYou.jpeg";
import { BuyGifts, CheckPaymentStatus } from "services/buy";
import theme from "assets/theme";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import useMediaQuery from '@mui/material/useMediaQuery';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from '@mui/material/CircularProgress';

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "muicomponents/MKBox";
import MKButton from "muicomponents/MKButton";
import MKTypography from "muicomponents/MKTypography";
import MKInput from "muicomponents/MKInput";

function BuyModal({ show, toggleModal, giftID }) {
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [screen, setScreen] = useState("form"); // form, loading, pix, thanks

  // Pix
  const [pixCode, setPixCode] = useState({ "id": "", "qrCode": "", "qrCodeBase64": "" })
  const [intervalObj, setIntervalObj] = useState(null)
  const [seconds, setSeconds] = useState(120)
  const [isActive, setIsActive] = useState(false)

  // Form
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [CPF, setCPF] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({ name: false, lastName: false, email: false, cpf: false, message: false });

  useEffect(() => {
    var timer;
    if (isActive && seconds > 0) {  
      timer = setTimeout(function () {
        setSeconds(seconds - 1);
      }, 1000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [seconds, isActive]);

  const sendForm = () => {
    // Validate
    const newErrors = {
      name: name == "",
      lastName: lastName == "",
      email: email == "" || !email.includes("@") || !email.includes("."),
      cpf: !cpf.isValid(CPF),
      message: message == ""
    }
    setErrors(newErrors)
    const isValid = Object.values(newErrors).every((v) => v == false)
    if (isValid) {
      setScreen("loading");
      BuyGifts({ giftID: giftID, name: name, lastName: lastName, email: email, CPF: CPF, message: message }).then((res) => {
        setPixCode(res)
        setScreen("pix")
      });
    }
  }

  const renderForm = () => (
    <Container>
      <Grid
        container
        item
        justifyContent="center"
        xs={10}
        lg={7}
        mx="auto"
        mb={{ xs: 0, md: 6 }}
        textAlign="center"
      >
        <MKTypography variant="h3" mb={1}>
          Presenteie o casal
        </MKTypography>
        <MKTypography variant="body2" color="text">
          Estamos felizes que tenha escolhido esse presente. Complete suas informações e deixe uma mensagem.
        </MKTypography>
      </Grid>
      <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
        <MKBox width="100%">
          <MKBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <MKInput
                  label="Nome"
                  fullWidth
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                  error={errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MKInput
                  type="Sobrenome"
                  label="Sobrenome"
                  fullWidth
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  error={errors.lastName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MKInput
                  type="email"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  error={errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MKInput
                  type="CPF"
                  label="CPF"
                  fullWidth
                  value={CPF}
                  onChange={(event) => {
                    setCPF(event.target.value);
                  }}
                  error={errors.cpf}
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  label="Deixe uma mensagem para nós :)"
                  multiline
                  fullWidth
                  rows={6}
                  value={message}
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                  error={errors.message}
                />
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" xs={12} my={6}>
              <MKButton variant="contained" color="info" onClick={sendForm}>
                Enviar
              </MKButton>
            </Grid>
          </MKBox>
        </MKBox>
      </Grid>
    </Container>
  )

  const renderPix = () => {
    if (!isActive) {
      setIsActive(true);
    }

    if (intervalObj === null && show) {
      var time = 5;
      var secs = seconds
      const poll = () => {
        secs = secs - 5;
        if (time >= 1) {
          time = time - 1;  
        } else if (secs>0) {
          CheckPaymentStatus(pixCode.id)
          .then(
            data => {
              if (data == "accepted") {
                clearInterval(intervalObj);
                setScreen("thanks");
              }
            }
          );
        } else {
          clearInterval(intervalObj);
        }
      }
      const interval = setInterval(poll, 5000);
      setIntervalObj(interval);
    }

    return (
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          mx="auto"
          textAlign="center"
          flexDirection="column"
        >
          <MKTypography variant="h3" mb={1}>
            Pix
          </MKTypography>
          <MKTypography variant="body2" color="text">
            Escaneie o QRCode ou copie a chave abaixo
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
          <MKBox width="100%">
            <MKBox p={3}>
              <Grid container>
                <Grid container flexDirection="row" width="100%">
                  <CopyToClipboard text={pixCode.qrCode}>
                    <IconButton size="small">
                      <ContentCopyIcon />
                    </IconButton>
                  </CopyToClipboard>
                  <MKBox
                    sx={{
                      overflowX: 'auto',
                      borderRadius: 1,
                    }}
                    bgColor="lightgrey"
                  >
                    <MKTypography
                      variant="pix"
                      color="text"
                      sx={{ whiteSpace: largeScreen ? 'nowrap' : 'break-spaces', maxWidth: '100%' }}
                    >
                      {pixCode.qrCode}
                    </MKTypography>
                  </MKBox>
                </Grid>
                <Grid container justifyContent="center">
                  <img width="50%" src={`data:image/png;base64,${pixCode.qrCodeBase64}`} />
                </Grid>
                <MKTypography
                  variant="caption"
                  color="text"
                  sx={{ whiteSpace: largeScreen ? 'nowrap' : 'break-spaces', maxWidth: '100%' }}
                >
                  Aguardando confirmação: {seconds} segundos
                </MKTypography>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    )
  }

  const renderThanks = () => (
    <Container>
      <Grid
        container
        item
        justifyContent="center"
        mx="auto"
        textAlign="center"
        flexDirection="column"
        md={9}
      >
        <MKTypography variant="h3" mb={1}>
          Obrigado
        </MKTypography>
        <MKTypography variant="body2" color="text" mb={1}>
          {name}, obrigado pelo presente e pela mensagem, estamos muito felizes por você fazer parte deste momento tão especial em nossas vidas. Esperamos por você dia 24 de Agosto!
        </MKTypography>
      </Grid>
      <Grid container item sx={{ mx: "auto", pb: 6 }} flexDirection="row" alignItems="center" justifyContent="center">
        <MKBox
          component="img"
          src={thankYou}
          width="70%"
          borderRadius="xl"
        />
      </Grid>
    </Container>
  )

  const renderLoading = () => (
    <Grid
      container
      justifyContent="center"
      textAlign="center"
      flexDirection="row"
      md={9}
      mx="auto"
      pb={30}
      pt={20}
    >
      <CircularProgress color="inherit" />
    </Grid>

  )

  const renderContent = () => {
    if (screen == "form") {
      return renderForm()
    }
    if (screen == "pix") {
      return renderPix()
    }
    if (screen == "thanks") {
      return renderThanks()
    }
    if (screen == "loading") {
      return renderLoading()
    }
  }

  return (
    <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }} >
      <Slide direction="down" in={show} timeout={500}>
        <MKBox
          position="relative"
          width={largeScreen ? "70%" : "90%"}
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          bgColor="white"
          shadow="xl"
          height={largeScreen ? "auto" : "95%"}
          overflow="scroll"
        >
          <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
            <MKTypography variant="h5"></MKTypography>
            <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
          </MKBox>
          {renderContent()}
        </MKBox>
      </Slide>
    </Modal>
  );
}

// Typechecking props for the MKBox
BuyModal.propTypes = {
  show: PropTypes.boolean,
  toggleModal: PropTypes.func,
  giftID: PropTypes.number,
};

export default BuyModal;
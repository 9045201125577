// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 components
import MKBox from "muicomponents/MKBox";
import MKTypography from "muicomponents/MKTypography";

import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

function Counter() {
  return (
    <MKBox
      component="section"
      position="relative"
      pt={6}
      px={{ xs: 2, lg: 0 }}
    >
      <Container spacing={3}>
        <Grid container justifyContent="center" flexDirection="column" alignItems="center">
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3">
              Contagem regressiva para o grande dia
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent="center" variant="gradient" bgColor="info">
          <Grid ml={{xs: 1.7, md: 0}}>
          <FlipClockCountdown
            to={new Date('2024-08-24T18:30:00')}
            labels={['DIAS', 'HORAS', 'MINUTOS', 'SEGUNDOS']}
            renderMap={[true,true,false,false]}
            showSeparators={false}
            labelStyle={{ fontSize: 10, fontWeight: 500, textTransform: 'uppercase', color: "#7b809a" }}
            digitBlockStyle={{ width: 40, height: 60, fontSize: 30, background: "#b9c1e6", color: "#465cc7" }}
            duration={0.5}
            hideOnComplete={false}
          />
          </Grid>
          <Grid ml={{md: 1}}>
          <FlipClockCountdown
            to={new Date('2024-08-24T18:30:00')}
            labels={['DIAS', 'HORAS', 'MINUTOS', 'SEGUNDOS']}
            renderMap={[false,false,true,true]}
            showSeparators={false}
            labelStyle={{ fontSize: 10, fontWeight: 500, textTransform: 'uppercase', color: "#7b809a" }}
            digitBlockStyle={{ width: 40, height: 60, fontSize: 30, background: "#b9c1e6", color: "#465cc7" }}
            duration={0.5}
            hideOnComplete={false}
          />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counter;

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: 
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AppBar from "@mui/material/AppBar";

// Material Kit 2 React components
import MKBox from "muicomponents/MKBox";
import MKBadge from "muicomponents/MKBadge";
import MKTypography from "muicomponents/MKTypography";
import MKButton from "muicomponents/MKButton";

import Gifts from "pages/Rental/sections/Gifts";
import amazon from "assets/images/wedding/amazon.png";

function GiftsPreview() {
  const { hash } = useLocation();

  const getTab = () => {
    return hash.replace("#", "")=="amazon" ? 1 : 0
  }

  const [activeTab, setActiveTab] = useState(getTab());

  useEffect(() => {
    setActiveTab(getTab());
  }, [hash]);

  const handleTabType = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderGifts = () => {
    return (
      <>
        <Gifts limit={6} />
        <MKBox mt={3} justifyContent="center" flexDirection="colum" display="flex">
          <MKButton variant="outlined" color="secondary" component={Link} to="/gifts">
            Lista completa
          </MKButton>
        </MKBox>
      </>
    )
  }

  const renderAmazon = () => {
    return (
      <Card sx={{ mt: 4 }}>
        
      <Grid
        container
        item
        flexDirection="column"
        alignItems="center"
        xs={12}
        lg={6}
        m={6}
        sx={{ textAlign: "center", mx: "auto" }}
      >

          <MKTypography variant="h4" mb={1}>
            Preparamos também uma lista de desejos na Amazon
          </MKTypography>
          <MKTypography variant="body2" color="text" mb={4}>
            Além da nossa lista do Pix preparamos uma lista de desejos na Amazon caso prefira nos presentear por lá.
          </MKTypography>
          <MKButton color="info" variant="outlined" target="_blank" rel="noreferrer" href="https://www.amazon.com.br/baby-reg/rodrigo-soares-agosto-2024-niteroi/1WD0GTAZQ49GC">
            <MKBox component="img" src={amazon} width="200px" m={1} />
          </MKButton>
      </Grid>
        </Card>
    )
  }

  return (
    <MKBox component="section" py={3}>
      <div id="giftsPreview"></div>
      <div id="amazon"></div>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Lista de presentes"
            container
            sx={{ mb: 1 }}
          />
          <MKTypography variant="h2" mb={1}>
            Faça parte dessa história
          </MKTypography>
          <MKTypography variant="body2" color="text">
            Seu amor é o maior presente que poderíamos desejar. Mas se quiser nos presentear de
            forma adicional, aqui estão algumas opções especiais.
          </MKTypography>
        </Grid>
        <Grid container sx={{ mt: 4, mb: -2 }}>
          <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto", textAlign: "center" }}>
            <AppBar position="static">
              <Tabs value={activeTab} onChange={handleTabType}>
                <Tab
                  id="pix"
                  label={
                    <MKBox py={0.5} px={2} color="inherit">
                      Pix
                    </MKBox>
                  }
                />
                <Tab
                  id="amazon"
                  label={
                    <MKBox py={0.5} px={2} color="inherit">
                      Amazon
                    </MKBox>
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        <Grid sx={{display: activeTab==0 ? "block" : "none"}}>
          {renderGifts()}
        </Grid>
        <Grid sx={{display: activeTab==1 ? "block" : "none"}}>
          {renderAmazon()}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default GiftsPreview;

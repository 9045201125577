import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "muicomponents/MKBox";
import MKTypography from "muicomponents/MKTypography";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Presentation page sections
import AboutUs from "pages/Presentation/sections/AboutUs";
import Details from "pages/Presentation/sections/Details";
import RsvpCta from "pages/Presentation/sections/RsvpCta";
import GiftsPreview from "pages/Presentation/sections/GiftsPreview";
import Counter from "pages/Presentation/sections/Counter";

// Presentation page components

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/wedding/header.jpg";
import flower from "assets/images/wedding/flower_long.png";


function Presentation() {
  const [isTransparent, setTransparent] = useState(false);

  const height = window.innerHeight * 0.9;
  const changeTransparency = () => {
    if (window.scrollY > height && !isTransparent) {
      setTransparent(true);
    } else if (window.scrollY < height && isTransparent) {
      setTransparent(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeTransparency);
  });

  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent={isTransparent}
        sticky
      />
      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.light.main, 0),
              rgba(gradients.light.state, 0)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 100%"
        }}
      >
        <Grid container item mb={24} justifyContent="center" mx="auto" flexDirection="column" alignItems="center">
          <MKTypography
            variant="h1"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["4xl"],
              },
            })}
          >
            Maria Eduarda
          </MKTypography>
          <MKTypography
            variant="h1"
            color="white"
            mb={{md: -3}}
            mt={{md: -3}}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["4xl"],
              },
            })}
          >
            &
          </MKTypography>
          <MKTypography
            variant="h1"
            color="white"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["4xl"],
              },
            })}
          >
            Rodrigo
          </MKTypography>
          <MKTypography
            variant="h1_5"
            color="white"
            textAlign="center"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            24 de Agosto, 2024
          </MKTypography>
        </Grid>
      </MKBox>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <AboutUs />
        <Grid
          container
          item
          xs={12}
          lg={9}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "justify" }}
          flexDirection="row"
          alignItems="center"
        >                    
          <MKBox component="img" src={flower} width={{xs: "60%", md:"20%"}} mr="10px" item />
        </Grid>
        <Counter />
        <Details />
        <RsvpCta />
        <GiftsPreview />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;

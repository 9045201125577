import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// SwiperJS react components
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// SwiperJS styles
// import "swiper/swiper.min.css";
import "swiper/css";

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit React components
import MKBox from "muicomponents/MKBox";
import MKBadge from "muicomponents/MKBadge";
import MKTypography from "muicomponents/MKTypography";
import MKButton from "muicomponents/MKButton";

// Images
import basilica from "assets/images/wedding/basilica.png";
import iate from "assets/images/wedding/iate.jpeg";
import passeio from "assets/images/wedding/passeio.png";
import calendar from "assets/images/wedding/google-calendar.png";

function Details() {
  const tabMap = { cerimonia: 0, recepcao: 1, traje: 2 }
  const { hash } = useLocation();

  const getTab = () => {
    return tabMap[hash.replace("#", "")] ? tabMap[hash.replace("#", "")] : 0
  }

  const [activeTab, setActiveTab] = useState(getTab());
  const [, setTabType] = useState("monthly");


  useEffect(() => {
    setActiveTab(getTab());
  }, [hash]);


  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  return (
    <MKBox component="section" position="relative" px={0} pt={12} id="detalhes">
      <div id="cerimonia"></div>
      <div id="recepcao"></div>
      <div id="traje"></div>
      <Container sx={{px:0}}>
        <MKBox>
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <MKBadge
              variant="contained"
              color="info"
              badgeContent="Detalhes"
              container
              sx={{ mb: 2 }}
            />
          </Grid>
          <Container>
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto", textAlign: "center" }}>
                <AppBar position="static">
                  <Tabs value={activeTab} onChange={handleTabType}>
                    <Tab
                      id="cerimoniaTab"
                      label={
                        <MKBox py={0.5} px={2} color="inherit">
                          Cerimônia
                        </MKBox>
                      }
                    />
                    <Tab
                      id="recepcaoTab"
                      label={
                        <MKBox py={0.5} px={2} color="inherit">
                          Recepção
                        </MKBox>
                      }
                    />
                    <Tab
                      id="trajeTab"
                      label={
                        <MKBox py={0.5} px={2} color="inherit">
                          Traje
                        </MKBox>
                      }
                    />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
            <Card>
              {/* BASILICA */}
              <Grid container justifyContent="center" display={activeTab == 0 ? "flex" : "none"}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
                    <MKBox p={4} justifyContent="center" flexDirection="column" display="flex">
                      <MKBox
                        component="img"
                        src={basilica}
                        width="100%"
                        borderRadius="xl"
                        maxHeight="37.5rem"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={5} px={2} pb={4} mr={{ xs: 0, lg: "auto" }} position="relative">
                    <MKTypography
                      component="h6"
                      variant="button"
                      opacity={0.7}
                      textTransform="uppercase"
                      fontWeight="bold"
                    >
                      Às 18:30
                    </MKTypography>
                    <MKTypography
                      variant="h6"
                      sx={{
                        fontSize: ({ typography: { d4, h1_5 } }) => ({
                          xs: h1_5.fontSize,
                          lg: d4.fontSize,
                        }),
                      }}
                    >
                      Cerimônia
                    </MKTypography>
                    <MKTypography sx={{fontSize:
                      ({ typography: { body1, body2 } }) => ({
                        xs: body2.fontSize,
                        lg: body1.fontSize,
                      })}
                    } mt={{xs:0, lg: 3}} mb={3}>
                      A cerimônia será realizada na Basílica Nossa Senhora Auxiliadora, Niterói/RJ. Às 18:30 do dia 24 de Agosto de 2024
                    </MKTypography>
                    <MKButton color="info" variant="outlined" size="small" target="_blank" rel="noreferrer" href="https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=Njk1cDdlZmttaDFldXZkNG5ubzJnOG5tNWggNjgyODA1YjU4YmUyMzM4N2FmZDJhNmI4ZGZjNGYxZTI4NzA5Zjg2YjRlMGUyNTZhNzc5NWFhZTAwMTUzOTBlMUBn&tmsrc=682805b58be23387afd2a6b8dfc4f1e28709f86b4e0e256a7795aae0015390e1%40group.calendar.google.com">
                      <MKBox component="img" src={calendar} width="20px" mr="10px" />
                      <MKTypography variant="text">
                        Adicionar ao Calendário
                      </MKTypography>
                    </MKButton>
                  </Grid>
                </Grid>
              </Grid>
              {/* IATE */}
              <Grid container justifyContent="center" display={activeTab == 1 ? "flex" : "none"}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
                    <MKBox p={4} justifyContent="center" flexDirection="column" display="flex">
                      <MKBox
                        component="img"
                        src={iate}
                        width="100%"
                        borderRadius="xl"
                        maxHeight="37.5rem"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={5} px={2} pb={4} mr={{ xs: 0, lg: "auto" }} position="relative">
                    <MKTypography
                      component="h6"
                      variant="button"
                      opacity={0.7}
                      textTransform="uppercase"
                      fontWeight="bold"
                    >
                      Após Cerimônia
                    </MKTypography>
                    <MKTypography
                      variant="h6"
                      sx={{
                        fontSize: ({ typography: { d4, h1_5 } }) => ({
                          xs: h1_5.fontSize,
                          lg: d4.fontSize,
                        }),
                      }}
                    >
                      Recepção
                    </MKTypography>
                    <MKTypography sx={{fontSize:
                      ({ typography: { body1, body2 } }) => ({
                        xs: body2.fontSize,
                        lg: body1.fontSize,
                      })}
                    } mt={{xs:0, lg: 3}} mb={3}>
                      A recepção será realizada no Iate Clube Icaraí após a cerimônia.
                    </MKTypography>
                  </Grid>
                </Grid>
              </Grid>
              {/* TRAJE */}
              <Grid container justifyContent="center" display={activeTab == 2 ? "flex" : "none"}>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
                    <MKBox p={4} justifyContent="center" flexDirection="column" display="flex">
                      <MKBox
                        component="img"
                        src={passeio}
                        width="100%"
                        borderRadius="xl"
                        maxHeight="37.5rem"
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={5} px={2} pb={4} mr={{ xs: 0, lg: "auto" }} position="relative">
                    <MKTypography
                      component="h6"
                      variant="button"
                      opacity={0.7}
                      textTransform="uppercase"
                      fontWeight="bold"
                    >
                      Passeio Completo
                    </MKTypography>
                    <MKTypography
                      variant="h6"
                      sx={{
                        fontSize: ({ typography: { d4, h1_5 } }) => ({
                          xs: h1_5.fontSize,
                          lg: d4.fontSize,
                        }),
                      }}
                    >
                      Traje
                    </MKTypography>
                    <MKTypography sx={{fontSize:
                      ({ typography: { body1, body2 } }) => ({
                        xs: body2.fontSize,
                        lg: body1.fontSize,
                      })}
                    } mt={{xs:0, lg: 3}} mb={3}>
                      O traje dos convidados para a cerimônia e festa será passeio completo.
                    </MKTypography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Container>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Details;

// Material Kit 2 React components
import MKTypography from "muicomponents/MKTypography";

// Images
import logoCT from "assets/images/wedding/logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    image: logoCT,
    route: "/pages/landing-pages/gifts",
  },
  socials: [],
  menus: [],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} DudrigoWeb by{" "}
      <MKTypography
        component="a"
        href="https://www.rodrigombs.xyz/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Rodrigombs Desenvolvimento de Sistemas LTDA
      </MKTypography>
      .
    </MKTypography>
  ),
};
